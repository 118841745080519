import axios from "axios";

axios.defaults.baseURL = "https://dev.meta-change.io/api/";
//axios.defaults.baseURL = "http://localhost:8080/";
//axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
//console.log(localStorage.getItem("token"));
const apiClient = {
  async login(urls, requestData) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    //axios.defaults.headers.common["xsrfHeaderName"] = "X-CSRFTOKEN";
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    //axios.defaults.headers.common["X-Csrftoken"] = localStorage.getItem("token");
    const response = await axios.post(urls, requestData);
    console.clear();
    return response.data;
  },

  async readAll(urls) {
    // add headers: {"Access-Control-Allow-Origin": "*"}
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    //axios.defaults.headers.common["xsrfHeaderName"] = "X-CSRFTOKEN";
    //axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    //axios.defaults.headers.common["X-Csrftoken"] = localStorage.getItem("token");


    const response = await axios.get(urls);
    //console.clear();
    return response.data;
  },
  async createUpdatedata(urls, requestData) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    /*axios.defaults.headers.common["xsrfHeaderName"] = "X-CSRFTOKEN";
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.defaults.headers.common["X-Csrftoken"] = localStorage.getItem("token");*/


    const response = await axios.post(urls, requestData);
    //console.clear();
    return response.data;
  },

  async updatedata_Put(urls, requestData) {
    console.log(urls);
    const response = await axios.put(urls, requestData, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
    return response.data;
  },

  async updatedata_Patch(urls, requestData) {
    const response = await axios.patch(urls, requestData);
    return response.data;
  },

  async read_Only(urls) {
    const response = await axios.get(urls);
    return response.data;
  },

  async post_data(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  },

  async delete_Only(urls) {
    const response = await axios.get(urls);
    return response.data;
  },
  async upload_Only(urls, requestData) {
    const response = await axios.post(urls, requestData);
    return response.data;
  },

  //send telegram message
  async sendTelegram(message) {
    // get the logged from local storage
      const user = JSON.parse(localStorage.getItem("userInfo"));
      const profil = JSON.parse(localStorage.getItem("profil"));
      const msgHeader = user.nom_prenom + " (" + profil.libelle + ") ==> ";
      message = msgHeader + "\n" + message;
     await axios.get("https://api.telegram.org/bot" + process.env.VUE_APP_TELEGRAM_TOKEN + "/sendMessage?chat_id=" + process.env.VUE_APP_TELEGRAM_CHAT_ID + "&text=" + message);
  },

};

export default apiClient;
